<template>
  <v-container fluid class="pa-0 ma-0">
    <hero></hero>
    <reduza></reduza>
    <beneficios></beneficios>
    <como-funciona></como-funciona>
  </v-container>
</template>

<script>
import hero from '@/components/agenda/hero.vue'
import reduza from '@/components/agenda/reduza.vue'
import beneficios from '@/components/agenda/beneficios.vue'
import ComoFunciona from '@/components/agenda/comoFunciona.vue'

export default {
  components: {
    hero,
    reduza,
    beneficios,
    ComoFunciona,
  },
}
</script>
